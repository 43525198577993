<template>
  <div class="hello ml-3 mt-3">
    <error-box :error="error" />
    <div v-if="!item && !error" class="d-flex justify-content-center">
      <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
    </div>
    <!--error cannot call a class as a function-->
    <!--<loading-spinner v-if="!item && !error"/>-->
    <div v-if="item" class="container mb-3">
      <div class="row mb-3">
        <div class="col-lg">
          <h3>{{ $t('Main Features') }}</h3>
          <b-form-row>
            <b-col class="col-12 col-sm-10 col-md-10">
              <field-form-bool v-model="item.forSale" v-bind:label="$t('forSale') | capitalize" />
            </b-col>
          </b-form-row>
          <b-collapse id="collapse-sale-price" :visible="item.forSale">
            <b-form-group label-for="salePrice" v-bind:label="$t('salePrice') | capitalize">
              <b-form-input v-model.number="item.salePrice"
                            id="salePrice"
                            type="number"></b-form-input>
            </b-form-group>
            <b-form-group label-for="salePrice" v-bind:label="$t('salePriceWithDiscount') | capitalize">
              <b-form-input v-model.number="item.salePriceWithDiscount"
                            id="salePriceWithDiscount"
                            type="number"></b-form-input>
            </b-form-group>
            <b-form-group label-for="dateOfSalePriceDiscount" v-bind:label="$t('dateOfSalePriceDiscount') | capitalize">
              <b-form-datepicker no-flip v-model="item.dateOfSalePriceDiscount" id="dateOfSalePriceDiscount"></b-form-datepicker>
            </b-form-group>
          </b-collapse>
          <b-form-row>
            <b-col class="col-12 col-sm-10 col-md-10">
              <field-form-bool v-model="item.forRent" v-bind:label="$t('forRent') | capitalize" />
            </b-col>
          </b-form-row>
          <b-collapse id="collapse-rent-price" :visible="item.forRent">
            <b-form-group label-for="rentPrice" v-bind:label="$t('rentPrice') | capitalize">
              <b-form-input v-model.number="item.rentPrice"
                            id="rentPrice"
                            type="number"></b-form-input>
            </b-form-group>
            <b-form-group label-for="rentPriceWithDiscount" v-bind:label="$t('rentPriceWithDiscount') | capitalize">
              <b-form-input v-model.number="item.rentPriceWithDiscount"
                            id="rentPriceWithDiscount"
                            type="number"></b-form-input>
            </b-form-group>
            <b-form-group label-for="dateOfRentPriceDiscount" v-bind:label="$t('dateOfRentPriceDiscount') | capitalize">
              <b-form-datepicker no-flip v-model="item.dateOfRentPriceDiscount" id="dateOfRentPriceDiscount"></b-form-datepicker>
            </b-form-group>
          </b-collapse>
          <b-form-row>
            <b-col class="col-12 col-sm-10 col-md-10">
              <field-form-bool v-model="item.forShortRent"
                               v-bind:label="$t('forShortRent') | capitalize" />
            </b-col>
          </b-form-row>
          <b-collapse id="collapse-short-rent-price" :visible="item.forShortRent">
            <b-form-group label-for="shortRentPrice"
                          v-bind:label="$t('shortRentPrice') | capitalize">
              <b-form-input v-model.number="item.shortRentPrice"
                            id="shortRentPrice"
                            type="number"></b-form-input>
            </b-form-group>
          </b-collapse>
          <b-form-row>
            <b-col class="col-12 col-sm-10 col-md-10">
              <b-form-group label-for="title" v-bind:label="$t('Title') + ' (add \'\\\\\\\\\' symbols at the end and write russian text)'">
                <b-form-input v-model="item.title"
                              id="title"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="col-12 col-sm-6 col-md-5">
              <b-form-group label-for="objectType" v-bind:label="$t('objectType') | capitalize">
                <b-form-select v-model="item.subTypeObjectTypeId"
                               :options="selectListsObjectTypes"
                               id="objectType"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="col-12 col-sm-6 col-md-5">
              <b-form-group label-for="objectSubType"
                            v-bind:label="$t('objectSubType') | capitalize">
                <b-form-select v-model="item.objectSubTypeId"
                               :options="selectListsObjectSubTypesOfType"
                               id="objectSubType"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-group label-for="addressSearch" v-bind:label="$t('location') | capitalize">
            <fieldLocation v-model="item.locationId" :text="item.location" />
          </b-form-group>
          <b-form-group label-for="area" v-bind:label="$t('area') | capitalize">
            <b-form-input v-model.number="item.area" id="area" type="number"></b-form-input>
          </b-form-group>
          <b-form-group label-for="bedrooms" v-bind:label="$t('bedrooms') | capitalize">
            <b-form-input v-model.number="item.bedrooms" id="bedrooms" type="number"></b-form-input>
          </b-form-group>
          <b-form-group label-for="bathrooms" v-bind:label="$t('bathrooms') | capitalize">
            <b-form-input v-model.number="item.bathrooms"
                          id="bathrooms"
                          type="number"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg">
          <object-edit-account :objectId="item.id"
                               :initialList="objectAccounts"
                               :relationTypes="objectAccountRelationSelectList" />
          <h3>{{ $t('description') | capitalize }} <span style="font-size: 0.5em;">(add '\\\\' symbols at the end and write russian text)</span></h3>
          <b-form-textarea v-model="item.description"
                           id="description"
                           rows="6"
                           max-rows="16"></b-form-textarea>
          <h3>{{ $t('internalNotes') }}</h3>
          <b-form-textarea v-model="item.internalNotes"
                           id="internalNotes"
                           rows="6"
                           max-rows="16"></b-form-textarea>
          <span> | {{ $t('Created') }}: {{ item.createdOn | date }} {{ item.createdBy }}</span>
          <span> | {{ $t('Modified') }}: {{ item.modifiedOn | date }} {{ item.modifiedBy }} </span>
          <field-form-bool v-model="item.active" v-bind:label="$t('active') | capitalize" />
          <field-form-bool v-model="item.publishRequested" v-bind:label="$t('published') | capitalize" />
          <p v-if="item.publishError" class="text-danger">
              {{ item.publishError }}
          </p>
          <p v-if="item.publishRequested && !item.published && !item.publishError" class="text-warning">
              Publish is in progress
          </p>
          
          <field-form-bool v-model="item.featured" v-bind:label="$t('featured') | capitalize" />
          <field-form-bool v-model="item.soldRecently"
                           v-bind:label="$t('sold recently') | capitalize" />
          <field-form-bool v-model="item.rented" v-bind:label="$t('rented') | capitalize" />
          <field-form-bool-nullable :label="$t('hasKey') | capitalize" v-model="item.hasKey">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('hasBanner') | capitalize" v-model="item.hasBanner">
          </field-form-bool-nullable>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <h3>{{ $t('Map') }}</h3>
          <ObjectEditMap :latitude="item.latitude"
                         :longitude="item.longitude"
                         :mapZoom="item.mapZoom"
                         :mapCircleLatitude="item.mapCircleLatitude"
                         :mapCircleLongitude="item.mapCircleLongitude"
                         :mapCircleRadius="item.mapCircleRadius" />
          <b-button size="sm" v-b-modal="'object-map-editor'">{{ $t('Change') }}</b-button>
          <field-form-bool v-model="item.displayCoordinates"
                           v-bind:label="$t('Display exact coordinates') | capitalize" />
        </div>
        <div class="col-lg">
          <h3>{{ $t('Location') }}</h3>
          <b-form-group label-for="zipCode" v-bind:label="$t('zipCode') | capitalize">
            <b-form-input v-model="item.zipCode" id="zipCode"></b-form-input>
          </b-form-group>
          <b-form-group label-for="address" v-bind:label="$t('address') | capitalize">
            <b-form-input v-model="item.address" id="address"></b-form-input>
          </b-form-group>
          <field-form-bool v-model="item.displayAddress"
                           v-bind:label="$t('displayAddress') | capitalize" />
          <b-form-group label-for="distanceFromSea"
                        v-bind:label="$t('distanceFromSea') | capitalize">
            <b-form-input v-model.number="item.distanceFromSea"
                          id="distanceFromSea"
                          type="number"></b-form-input>
          </b-form-group>
          <b-form-group label-for="distanceFromAirport"
                        v-bind:label="$t('distanceFromAirport') | capitalize">
            <b-form-input v-model.number="item.distanceFromAirport"
                          id="distanceFromAirport"
                          type="number"></b-form-input>
          </b-form-group>
          <b-form-group label-for="accessFrom" v-bind:label="$t('accessFrom') | capitalize">
            <b-form-select v-model="item.accessFrom"
                           :options="selectListsAccessFrom"
                           id="accessFrom"></b-form-select>
          </b-form-group>
          <b-form-group label-for="orientation" v-bind:label="$t('orientation') | capitalize">
            <b-form-checkbox-group id="orientation"
                                   v-model="item.orientation"
                                   :options="selectListsOrientation"
                                   name="orientation"></b-form-checkbox-group>
          </b-form-group>
          <b-form-group label-for="zone" v-bind:label="$t('zone') | capitalize">
            <b-form-select v-model="item.zone" :options="selectListsZone" id="zone"></b-form-select>
          </b-form-group>
        </div>
      </div>
      <h3>{{ $t('Additional Features') }}</h3>
      <div class="row">
        <div class="col-lg">
          <b-form-group label-for="floor" v-bind:label="$t('floor') | capitalize">
            <b-form-input v-model.number="item.floor" id="floor" type="number"></b-form-input>
          </b-form-group>
          <b-form-group label-for="levels" v-bind:label="$t('levels') | capitalize">
            <b-form-input v-model.number="item.levels" id="levels" type="number"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('elevator') | capitalize" v-model="item.elevator">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('internalStaircase') | capitalize"
                                    v-model="item.internalStaircase">
          </field-form-bool-nullable>
          <b-form-group label-for="livingRooms" v-bind:label="$t('livingRooms') | capitalize">
            <b-form-input v-model.number="item.livingRooms" id="livingRooms"></b-form-input>
          </b-form-group>
          <b-form-group label-for="kitchens" v-bind:label="$t('kitchens') | capitalize">
            <b-form-input v-model.number="item.kitchens" id="kitchens"></b-form-input>
          </b-form-group>
          <b-form-group label-for="wc" v-bind:label="$t('wc') | capitalize">
            <b-form-input v-model.number="item.wc" id="wc"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('storageSpace') | capitalize"
                                    v-model="item.storageSpace">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('playroom') | capitalize" v-model="item.playroom">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('luxurious') | capitalize" v-model="item.luxurious">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('penthouse') | capitalize" v-model="item.penthouse">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('neoclassical') | capitalize"
                                    v-model="item.neoclassical">
          </field-form-bool-nullable>
          <b-form-group label-for="lotArea" v-bind:label="$t('lotArea') | capitalize">
            <b-form-input v-model.number="item.lotArea" id="lotArea" type="number"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('Buildable Land') | capitalize" v-model="item.buildableLand">
          </field-form-bool-nullable>
          <b-form-group label-for="buildableArea" v-bind:label="$t('buildableArea') | capitalize">
            <b-form-input v-model.number="item.buildableArea" id="buildableArea" type="number"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('garden') | capitalize" v-model="item.garden">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('swimmingPool') | capitalize"
                                    v-model="item.swimmingPool">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('awning') | capitalize" v-model="item.awning">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('parking') | capitalize" v-model="item.parking">
          </field-form-bool-nullable>
          <b-form-group label-for="parkingLots" v-bind:label="$t('parkingLots') | capitalize">
            <b-form-input v-model.number="item.parkingLots"
                          id="parkingLots"
                          type="number"></b-form-input>
          </b-form-group>
          <b-form-group label-for="constructionYear" v-bind:label="$t('constructionYear') | capitalize">
            <b-form-input v-model.number="item.constructionYear"
                          id="constructionYear"
                          type="number"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('underConstruction') | capitalize"
                                    v-model="item.underConstruction">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('renovated') | capitalize" v-model="item.renovated">
          </field-form-bool-nullable>
          <b-form-group label-for="renovationYear" v-bind:label="$t('renovationYear') | capitalize">
            <b-form-input v-model.number="item.renovationYear"
                          id="renovationYear"
                          type="number"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('newBuilding') | capitalize" v-model="item.newBuilding">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('requiredRenovation') | capitalize"
                                    v-model="item.requiredRenovation">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('newDevelopment') | capitalize"
                                    v-model="item.newDevelopment">
          </field-form-bool-nullable>
        </div>
        <div class="col-lg">
          <b-form-group label-for="energyClass" v-bind:label="$t('energyClass') | capitalize">
            <b-form-select v-model="item.energyClass"
                           :options="selectListsEnergyClass"
                           id="energyClass"></b-form-select>
          </b-form-group>
          <b-form-group label-for="heating" v-bind:label="$t('heating') | capitalize">
            <b-form-select v-model="item.heating"
                           :options="selectListsHeating"
                           id="heating"></b-form-select>
          </b-form-group>
          <b-form-group label-for="heatingMedium" v-bind:label="$t('heatingMedium') | capitalize">
            <b-form-select v-model="item.heatingMedium"
                           :options="selectListsHeatingMedium"
                           id="heatingMedium"></b-form-select>
          </b-form-group>
          <field-form-bool-nullable :label="$t('underFloorHeating') | capitalize"
                                    v-model="item.underFloorHeating">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('fireplace') | capitalize" v-model="item.fireplace">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('solarWaterHeating') | capitalize"
                                    v-model="item.solarWaterHeating">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('airCondition') | capitalize"
                                    v-model="item.airCondition">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('windowScreens') | capitalize"
                                    v-model="item.windowScreens">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('doubleGlass') | capitalize"
                                    v-model="item.doubleGlass">
          </field-form-bool-nullable>
          <b-form-group label-for="framesType" v-bind:label="$t('framesType') | capitalize">
            <b-form-select v-model="item.framesType"
                           :options="selectListsFramesType"
                           id="framesType"></b-form-select>
          </b-form-group>
          <b-form-group label-for="floorsType" v-bind:label="$t('floorsType') | capitalize">
            <b-form-select v-model="item.floorsType"
                           :options="selectListsFloorsType"
                           id="floorsType"></b-form-select>
          </b-form-group>
          <field-form-bool-nullable :label="$t('alarm') | capitalize" v-model="item.alarm">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('secureDoor') | capitalize"
                                    v-model="item.secureDoor">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('nightPower') | capitalize"
                                    v-model="item.nightPower">
          </field-form-bool-nullable>
        </div>
        <div class="col-lg">
          <field-form-bool-nullable :label="$t('balcony') | capitalize" v-model="item.balcony">
          </field-form-bool-nullable>
          <b-form-group label-for="sizeOfBalconies"
                        v-bind:label="$t('sizeOfBalconies') | capitalize">
            <b-form-input v-model.number="item.sizeOfBalconies" id="sizeOfBalconies"></b-form-input>
          </b-form-group>
          <field-form-bool-nullable :label="$t('bright') | capitalize" v-model="item.bright">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('facade') | capitalize" v-model="item.facade">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('corner') | capitalize" v-model="item.corner">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('airy') | capitalize" v-model="item.airy">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('painted') | capitalize" v-model="item.painted">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('disabledAccess') | capitalize"
                                    v-model="item.disabledAccess">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('attic') | capitalize" v-model="item.attic">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('view') | capitalize" v-model="item.view">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('Sea View') | capitalize" v-model="item.seaView">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('First Coast Line') | capitalize" v-model="item.firstCoastLine">
          </field-form-bool-nullable>
        </div>
      </div>
      <h3>{{ $t('Offer Details') }}</h3>
      <div class="row">
        <div class="col-lg">
          <b-form-group label-for="dateAvailable" v-bind:label="$t('dateAvailable') | capitalize">
            <b-form-datepicker no-flip v-model="item.dateAvailable" id="dateAvailable"></b-form-datepicker>
          </b-form-group>
          <field-form-bool-nullable :label="$t('unfinished') | capitalize"
                                    v-model="item.unfinished">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('preserved') | capitalize" v-model="item.preserved">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('furnished') | capitalize" v-model="item.furnished">
          </field-form-bool-nullable>
          <b-form-group label-for="averageMonthlySharedExpenses"
                        v-bind:label="$t('averageMonthlySharedExpenses') | capitalize">
            <b-form-input v-model.number="item.averageMonthlySharedExpenses"
                          id="averageMonthlySharedExpenses"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg">
          <field-form-bool-nullable :label="$t('student') | capitalize" v-model="item.student">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('petsWelcome') | capitalize"
                                    v-model="item.petsWelcome">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('suitableForCommercialUse') | capitalize"
                                    v-model="item.suitableForCommercialUse">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('Eligible for Golden Visa')"
                                    v-model="item.eligibleForGoldenVisa">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('Our Project')"

                                    v-model="item.ourProject">
          </field-form-bool-nullable>        
        </div>
        <div class="col-lg">
          <field-form-bool-nullable :label="$t('hasKey') | capitalize" v-model="item.hasKey">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('hasBanner') | capitalize" v-model="item.hasBanner">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('investment') | capitalize"
                                    v-model="item.investment">
          </field-form-bool-nullable>
          <field-form-bool-nullable :label="$t('noAgentFeeForBuyer') | capitalize"
                                    v-model="item.noAgentFeeForBuyer">
          </field-form-bool-nullable>
        </div>
      </div>
      <h3>{{ $t('Photos') }}</h3>
      <b-form-group label-for="renovationYear" v-bind:label="$t('Video Link') | capitalize">
        <b-form-input v-model.number="item.videoURL"
                      id="videoURL"
                      type="text"></b-form-input>
      </b-form-group>

      <file-pond name="objectImages"
                 ref="pond"
                 label-idle="Drop files here..."
                 v-bind:allow-multiple="true"
                 accepted-file-types="image/*, video/*, audio/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.visio, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, application/zip, application/x-7z-compressed"
                 server="/api/files"
                 v-bind:files="imagesExisting"
                 v-on:processfile="imageLoaded"
                 v-bind:allowReorder="true"
                 v-on:reorderfiles="imagesReodered" />
    </div>
    <object-edit-map-editor :markerLat="item.latitude"
                            :markerLng="item.longitude"
                            :mapZoom="item.mapZoom"
                            :circleRequired="!item.displayCoordinates"
                            :circleLat="item.mapCircleLatitude"
                            :circleLng="item.mapCircleLongitude"
                            :circleRadius="item.mapCircleRadius"
                            v-on:changed="coordinatesChanged" />
    <b-modal id="errorModal" title="Error" ok-only>
      <div v-if="error">
        <p class="my-4">{{ error.message }}</p>
        <div v-if="error.details">
          <br />
          <br />
          <b-button size="sm" v-b-toggle.collapse-error-modal variant="light">Details</b-button>
          <b-collapse id="collapse-error-modal" class="mt-2">
            <p>
              {{ error.details }}
            </p>
          </b-collapse>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
  import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
  import { Debounce } from 'vue-debounce-decorator'

  import vueFilePond from 'vue-filepond'
  import { FilePondFile } from 'filepond'

  import 'filepond/dist/filepond.min.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

  import fieldLocation from '@/components/Shared/FieldLocation.vue'
  import fieldFormBool from '@/components/Shared/FieldFormBool.vue'
  import fieldFormBoolNullable from '@/components/Shared/FieldFormBoolNullable.vue'
  import ObjectEditMap from '@/components/Objects/ObjectEditMap.vue'
  import ObjectEditAccount from '@/components/Objects/ObjectEditAccount.vue'
  import ObjectEditMapEditor from '@/components/Objects/ObjectEditMapEditor.vue'
  import { api } from '@/services/api'
  import { ObjectDto } from '@/models/ObjectDto'
  import { ObjectEditQueryOutput, ObjectSelectLists } from '@/models/ObjectEditQueryOutput'
  import SelectListItem from '@/models/SelectListItem'
  //import BaseComponent from '@/components/Shared/BaseComponent.vue'
  import { EventBus } from '@/components/Shared/EventBus'

  const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

  @Component({
    components: {
      fieldLocation,
      FilePond,
      ObjectEditMap,
      ObjectEditMapEditor,
      ObjectEditAccount,
      fieldFormBool,
      fieldFormBoolNullable
    }
  })
  export default class ObjectEdit extends Vue {
    @Prop({ type: Object, required: true })
    public dto!: ObjectEditQueryOutput

    item: ObjectDto = new ObjectDto()
    error: Error | null = null
    itemLoaded = false
    selectLists: ObjectSelectLists | null = null
    objectAccounts: [] = []
    objectAccountRelationSelectList: [] = []
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    imagesExisting: any[] | null = null

    @Watch('dto')
    applyProp(): void {
      this.item = this.dto.object
      this.selectLists = this.dto.objectSelectLists
      this.objectAccounts = this.dto.objectAccounts
      this.objectAccountRelationSelectList = this.dto.objectAccountRelationSelectList
      this.setInitialImages()
    }

    @Debounce(400)
    @Watch('item', { deep: true })
    public save(): void {
      if (!this.itemLoaded) {
        this.itemLoaded = true
        return
      }
      api.post('/api/object/update', this.item)
    }

    @Watch('error')
    public showErrorModal(): void {
      this.$bvModal.show('errorModal')
    }

    get selectListsObjectTypes(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.objectTypes as SelectListItem[])
    }
    get selectListsObjectSubTypesOfType(): SelectListItem[] {
      if (!this.selectLists?.objectSubTypes) return []
      const filtered = (this.selectLists?.objectSubTypes as SelectListItem[]).filter(
        (item) => item.parent == this.item?.subTypeObjectTypeId
      )
      return this.translateSelectList(filtered)
    }
    get selectListsAccessFrom(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.accessFrom as SelectListItem[])
    }
    get selectListsOrientation(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.orientation as SelectListItem[])
    }
    get selectListsZone(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.zone as SelectListItem[])
    }
    get selectListsHeating(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.heating as SelectListItem[])
    }
    get selectListsHeatingMedium(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.heatingMedium as SelectListItem[])
    }
    get selectListsEnergyClass(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.energyClass as SelectListItem[])
    }
    get selectListsFramesType(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.framesType as SelectListItem[])
    }
    get selectListsFloorsType(): SelectListItem[] {
      return this.translateSelectList(this.selectLists?.floorsType as SelectListItem[])
    }
    //get isResidentialAnyRent(): boolean {
    //  return !this.item?.objectSubTypeId || !this.item.forRent || !this.item.forShortRent
    //    ? false
    //    : this.item.objectSubTypeId <= 6 && (this.item.forRent || this.item.forShortRent)
    //}
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    setInitialImages(): void {
      if (!this.item?.imagesExisting) {
        this.imagesExisting = []
        return
      }
      this.imagesExisting = this.item.imagesExisting.map((i) => {
        return { options: { type: 'limbo' }, source: i } as any
      })
    }

    imageLoaded(error: Error, file: FilePondFile): void {
      if (error) this.error = error
      else {
        if (!this.item?.imagesExisting?.includes(file.serverId))
          this.item.imagesNew =
            this.item?.imagesNew == null ? file.serverId : this.item.imagesNew + ',' + file.serverId
      }
    }

    imagesReodered(pondFiles: FilePondFile[]): void {
      const files = pondFiles.map((file) => file.serverId)
      api.post<string[]>('/api/files/reorder', files)
    }

    showMapEditor(): void {
      EventBus.$emit('ObjectMapEditorShow')
    }

    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
    coordinatesChanged(newCoordinates: any): void {
      this.item.latitude = newCoordinates.markerLat
      this.item.longitude = newCoordinates.markerLng
      this.item.displayCoordinates = !newCoordinates.circleRequired
      this.item.mapCircleLatitude = newCoordinates.circleLat
      this.item.mapCircleLongitude = newCoordinates.circleLng
      this.item.mapCircleRadius = newCoordinates.circleRadius
      this.item.mapZoom = newCoordinates.mapZoom
    }
    public translateSelectList(src: SelectListItem[]): SelectListItem[] {
      //const self = this
      if (!src) return []
      return src.map((v: SelectListItem) => {
        return new SelectListItem({
          parent: v.parent,
          value: v.value,
          text: this.$tc(v.text as string)
        })
      })
    }
  }
</script>

<style scoped></style>

